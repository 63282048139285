export enum EOnboardingUrls {
  BASE = 'onboarding',
  ANIMAL = 'animal',
  COMPLETE = 'complete',
  ANIMAL_TO_APPLY = 'animal-to-apply',
  PAYMENT = 'payment',
  CREATE_APPLICATION = 'create-applications',
  CODE = 'code',
  TYPE_CONFIRM = 'type-confirm',
  SIGNATURE = 'signature',
}
