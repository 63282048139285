import { TCreditCardDetailProps } from 'components/Shared/PaymentComponents/CreditCard/CreditCard.types';
import { Moment } from 'moment';
import {
  EApplicationStatuses,
  EApplicationStatusesUi,
  TFail,
  TFailUI,
  TOptionTypeUi,
} from 'data/types/generalTypes';
import {
  EMoveOutOrRetractReasonTypeDB,
  EMoveOutOrRetractReasonTypeUI,
  TApplicationAnimal,
  TApplicationAnimalUI,
} from './animal.types';
import { TApplicationProfile, TApplicationProfileUI } from './tenant.types';

export type TApplicationStep = {
  stepName: EApplicationStepName;
  stepType: EApplicationStepType;
  subtitle: string;
  stepImage: string;
  title: string;
  withWarnings?: boolean;
};

export type TApplicationStepUi = {
  stepName: EApplicationStepNameUi;
  stepType: EApplicationStepTypeUi;
  subTitle: string;
  stepImage?: string;
  title: string;
  withWarnings?: boolean;
};

export type TPaymentDetail = {
  currentPriceForGivenAnimal: number;
  fails: TFail;
  firstAnimalPriceInCents: number;
  otherAnimalPriceInCents: number;
  payedUntil: string;
  paymentMethod: TCreditCardDetailProps;
};

export type TPaymentDetailUi = {
  currentPriceForGivenAnimal: number;
  fails: TFailUI[];
  firstAnimalPriceInCents: number;
  otherAnimalPriceInCents: number;
  payedUntil: string;
  paymentMethod: TCreditCardDetailProps;
};

export enum EApplicationStepName {
  'Property management' = 'Property management',
  SA = 'SA',
  ESA = 'ESA',
  PET = 'PET',
  'Profile info' = 'Profile info',
  Training = 'Training',
  Payment = 'Payment',
}

export enum EApplicationStepNameUi {
  'Property management' = 'Property management',
  SA = 'SA',
  ESA = 'ESA',
  PET = 'PET',
  'Profile info' = 'Profile info',
  Training = 'Training',
  Payment = 'Payment',
}

export enum EApplicationStepTypeUi {
  PROPERTY_MANAGEMENT = 'PROPERTY_MANAGEMENT',
  TENANT_PROFILE = 'TENANT_PROFILE',
  ANIMAL = 'ANIMAL',
  BILLING = 'BILLING',
}

export enum EApplicationStepType {
  PROPERTY_MANAGEMENT = 'PROPERTY_MANAGEMENT',
  TENANT_PROFILE = 'TENANT_PROFILE',
  ANIMAL = 'ANIMAL',
  TRAINING = 'TRAINING',
  BILLING = 'BILLING',
}
export type TLeasePacket = {
  leasePacketId: string;
  generatedDocUrl: string;
  propertyManagementName: string;
  propertyManagementLogo: string;
  propertyManagerName: string;
  propertyManagerId: string;
};

export type TLeasePacketUi = {
  generatedDocUrl: string;
  leasePacketId?: string;
  propertyManagementName?: string;
  propertyManagementLogo?: string;
  propertyManagerName?: string;
  propertyManagerId: string;
};

export type TLeasePacketPropertyUi = {
  propertyId: string;
  name: string;
  picture: string;
  isSingleFamily: boolean;
  city: string;
  state: string;
};

export type TLeasePacketProperty = {
  propertyId: string;
  name: string;
  picture: string;
  singleFamily: boolean;
  city: string;
  state: string;
};

export type TLeasePacketUnitUi = {
  unitId: string;
  name: string;
};
export type TLeasePacketUnit = {
  unitId: string;
  name: string;
};

export type TStepVerificationParams = {
  animalId?: string;
  leasePacketId?: string;
  type: EApplicationStepTypeUi;
};

export type TStepVerificationUI = {
  allowedNextStep: boolean;
  fails: TFailUI[];
};
export type TStepVerification = TStepVerificationUI;
export type DocTypes = 'PET_FORM' | 'SERVICE_FORM' | 'ESA_FORM' | 'GENERAL_FORM';
export type TSignatureUI = { name: string; date: string; docType?: DocTypes };
export type TSignature = { name: string; date: string; docType?: DocTypes };

export type TPropertyManagementForm = {
  leasePacket: { value: TLeasePacketUi };
  propertyName: { value: TLeasePacketPropertyUi | string };
  unit: { value: TLeasePacketUnitUi | string };
  startTime: Moment;
  endTime?: Moment | null;
};

export type TPropertyManagementUI = {
  leasePacket: TLeasePacketUi;
  property: TLeasePacketPropertyUi | string;
  unit: TLeasePacketUnitUi | string;
  moveIn: Moment;
  leaseTo?: Moment | null;
};

export type TApplicationSignUI = {
  applicationId: string;
  signature: TSignatureUI;
  animalId: string;
};

export type TApplicationSignDB = {
  applicationId: string;
  signature: TSignature;
  animalId: string;
};

export type TApplicationCreateUI = TApplicationResidencyInfoUI & {
  animalId: string;
  signature: TSignatureUI;
  residencyApplicationId?: string;
  residenceId?: string;
  animalMoveInDate?: Moment;
};

export type TApplicationCreate = {
  animalId: string;
  signature: TSignature;
  residencyApplicationId?: string;
  tenantResidencyId?: string;
  moveInDate?: string;
};

export type TRecordApplicationStatisticsUI = {
  animalId: string;
  leasePacketId: string;
};

export type TRecordApplicationStatistics = TRecordApplicationStatisticsUI;

export type TPMStepDetailsUI = {
  endTime?: Moment | null;
  startTime?: Moment | null;
  propertyName?: string | null;
  unit?: TOptionTypeUi | null;
};
export type TPMStepDetails = {
  leaseToDate: string | null;
  moveInDate: string | null;
  propertyName?: string | null;
  unitNumber: string | null;
};

export type TApplicationStepsDetailDB = {
  hasFails?: boolean;
} & TApplicationStep;
export type TApplicationStepsDetailUI = {
  hasFails?: boolean;
} & TApplicationStepUi;

export type TPropertyManagementDetailsDB = TPMStepDetails &
  Omit<TLeasePacket, 'propertyManagerId' | 'generatedDocUrl'> & {
    signature: TSignature | null;
    animalMoveInDate?: string;
  };

export type TPropertyManagementDetailsUI = Omit<TPMStepDetailsUI, 'unit'> &
  Omit<TLeasePacketUi, 'propertyManagerId' | 'generatedDocUrl'> & {
    signature?: TSignatureUI | null;
    unit?: string;
    animalMoveInDate?: string;
  };

export type TPmAndLeasePacketStepDetailsDB = TApplicationStepsDetailDB & {
  propertyManagementDetails: TPropertyManagementDetailsDB;
};
export type TPmAndLeasePacketStepDetailsUI = {
  stepData: TApplicationStepsDetailUI;
  propertyManagementDetails: TPropertyManagementDetailsUI;
};

export type TAnimalStepDetailDB = TApplicationStepsDetailDB & {
  animalDetails: TApplicationAnimal;
};
export type TAnimalStepDetailUI = {
  stepData: TApplicationStepsDetailUI;
  animalDetails: TApplicationAnimalUI;
};

export type TProfileStepDetailDB = TApplicationStepsDetailDB & {
  tenantDetails: TApplicationProfile;
};
export type TProfileStepDetailUI = {
  stepData: TApplicationStepsDetailUI;
  tenantDetails: TApplicationProfileUI;
};

export type TApplicationDetailDB = {
  animalMoveInDate?: string;
  id: string;
  status: EApplicationStatuses;
  rejectReason: string | null;
  applicationSteps: [
    TPmAndLeasePacketStepDetailsDB,
    TAnimalStepDetailDB,
    TProfileStepDetailDB,
  ];
};

export type TApplicationDetailUI = {
  animalMoveInDate?: string;
  id: string;
  status: EApplicationStatusesUi;
  rejectReason: string | null;
  applicationSteps: {
    [EApplicationStepTypeUi.PROPERTY_MANAGEMENT]: TPmAndLeasePacketStepDetailsUI;
    [EApplicationStepTypeUi.ANIMAL]: TAnimalStepDetailUI;
    [EApplicationStepTypeUi.TENANT_PROFILE]: TProfileStepDetailUI;
  };
};

export enum EPropertyManagementStatusDB {
  APPLICANT = 'APPLICANT',
  RESIDENT = 'RESIDENT',
}

export enum EPropertyManagementStatusUI {
  APPLICANT = 'Applicant',
  RESIDENT = 'Resident',
}
export type TApplicationResidencyInfo = {
  id: string;
  leasePacketId: string;
  leaseToDate: string;
  moveInDate: string;
  propertyManagementId: string;
  propertyManagementLogo: string;
  propertyManagementName: string;
  propertyName: string;
  unitNumber: string;
  policyId: string;
  policyName: string;
  petsAllowed: boolean;
  status: EPropertyManagementStatusDB;
};

export type TApplicationResidencyInfoUI = {
  id: string;
  leasePacketId: string;
  leaseToDate: string;
  moveInDate: string;
  propertyManagementId: string;
  propertyManagementLogo: string;
  propertyManagementName: string;
  propertyName: string;
  unitNumber: string;
  policyId: string;
  policyName: string;
  petsAllowed: boolean;
  status: EPropertyManagementStatusUI;
};

export type TPropertyManagementStepData = {
  residencyInfos: TApplicationResidencyInfo[];
};

export type TPropertyManagementStepDataUI = {
  residencyInfos: TApplicationResidencyInfoUI[];
};

export type TRetractReasonDB = {
  other?: string;
  predefined?: EMoveOutOrRetractReasonTypeDB;
};
export type TRetractReasonUI = {
  other?: string;
  predefined?: EMoveOutOrRetractReasonTypeUI;
};

export type TRetractApplicationDB = {
  applicationIds: string[];
  reason: TRetractReasonDB;
};

export type TRetractApplicationUI = {
  id: string[];
  reason?: EMoveOutOrRetractReasonTypeUI;
  other?: string;
};

export type TReapplyApplicationUI = {
  animalId: string;
  residencyId: string;
  reason?: string;
  moveInDate: string;
};
export type TReapplyApplicationDB = {
  animalId: string;
  residencyId: string;
  reason?: string;
  moveInDate: string;
};
