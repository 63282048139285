export const yesNoOptions = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
];

export const noYesTrueFalseOptions = [
  { value: false, label: 'No' },
  { value: true, label: 'Yes' },
];

export const yesNoTrueFalseOptions = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];

export const hasESADocumentationOptions = [
  { value: true, label: 'I have health professional documentation' },
  { value: false, label: `I don't have health professional documentation` },
];

export const hasSADocumentationOptions = [
  { value: true, label: 'I have health professional documentation' },
  { value: false, label: `I don't have health professional documentation` },
];

export const hasSATrainerDocumentationOptions = [
  { value: true, label: 'I have training documentation' },
  { value: false, label: 'I don’t have training documentation' },
];

export const isDisabilityObservableOptions = [
  {
    value: true,
    label: 'Observable disability',
    subtitle:
      'Observable impairments generally tend to be obvious. Observable impairments include blindness or low vision, deafness or being hard of hearing, mobility limitations, and other types of impairments with observable symptoms or effects.',
  },
  {
    value: false,
    label: 'Non observable disability',
    subtitle:
      'Non-Observable Disabilities, impairments that may seem invisible or are not always readily apparent.',
  },
];

export const knownQRLinks = [
  'https://qr.page/g/1JsGfIbmIB8',
  'https://qr.page/g/47c1CCwMhJq',
  'https://qr.page/g/1bIfeVcgCMg',
  'https://forms.gle/vhrSYGcN3pG19zVR6',
  'https://forms.gle/tXaKCJ5RiqgPZ6k38',
  'https://forms.gle/XRfyVfXH4EDo4NdZ9',
  'https://forms.gle/dfXSY36CqNanChYs9',
  'https://forms.gle/aZULcDm9aW94WwaHA',
  'https://forms.gle/2prbPKNQFsaZDMce8',
  'https://forms.gle/Vr9D6J76uqzaf4Nk9',
  'https://forms.gle/mNUjjUADnSfQNFBL6',
  'https://forms.gle/3a1Bztj3R7UdgUEZ6',
  'https://forms.gle/b6GWj42R4bCxTfqp9',
  'https://forms.gle/hYRfwC9LZRA3ydqz6',
  'https://forms.gle/utzT6GV397923po3A',
  'https://forms.gle/JUhSrW8To8Hryd89A',
  'https://forms.gle/3a1Bztj3R7UdgUEZ6',
  'https://forms.gle/P6DMWT8xN4kurfko9',
  'https://forms.gle/5Y2GXjDXMFhMZeEB8',
  'https://forms.gle/Q7ispbWwrwv2k8sd9',
  'https://forms.gle/j8tmtYFhSxuVp1aW8',
  'https://forms.gle/99VyUjqAFNgYrugR6',
  'https://forms.gle/s8Ss6VE3phe4W6Mb8',
  'https://forms.gle/xcXhoiinFxwHNd5K6',
  'https://forms.gle/UPPHgwVB8tCFiHxV6',
  'https://forms.gle/CqHcvoY9Vf6rWtkn6',
  'https://forms.gle/ABcP7HZpmVhUN7vV8',
];

export enum EText {
  DEFAULT_ERROR = 'Something went wrong',
  HP_FORM_TEXT = 'The quickest and most effective method of verifying your documentation is with the Health Professional Form attached below sent immediately to your Health Professional. It does not ask any specific details about the nature of your disability, only that the process is in accordance to the HUD guidelines. If you would prefer to verify the documentation another way please contact us at support@ourpetpolicy.com or (208)906-8886.',
  VACCINATION_OTHER_NO_EXPIRE = 'Vaccination has no expiration.',
  VACCINATION_RABBIES_NO_EXPIRE = 'Documentation is from a licensed veterinarian exempting my animal from a rabies vaccination due to the animals age/health condition.',
  DONT_HAVE_CODE = 'If you do not know the code, please ask the property manager or email support@ourpetpolicy.com the adress and name of property, management company to get this code.',
  MICROCHIP_ERROR = 'This is an uncommon microchip length! Please double check the microchip.',
}

export enum EAnimalInfoContent {
  ANIMAL_VERIFIED_STATUS_INFO = 'The animal is verified as all documentation has been reviewed and verified as being valid and up to date.',
  ANIMAL_NOT_VERIFIED_STATUS_INFO = 'The animal is verified once all documentation has been reviewed and verified as being valid and up to date.',
}

export const convertedToPetNoReasonText = `The animal has not been verified as an assistance animal.   For your animal to be considered as assistance
animal, you can submit reliable documentation at
anytime by requesting to convert your animal.`;

export const convertedToPetReasonStaticText = {
  pre: `The animal has not been verified as an assistance animal with the reason:`,
  post: `For your animal to be considered as assistance animal, you can submit reliable documentation at anytime by requesting to convert your animal.`,
};

export const vaccinationData = [
  'Rabies',
  'Parvo',
  'Spayed/Neutered',
  'Dog liability insurance',
];

export enum EConversionReasonDB {
  SEVERAL_ATTEMPTS_TO_VERIFY = 'SEVERAL_ATTEMPTS_TO_VERIFY',
  NOT_RECEIVED_DOCUMENTATION = 'NOT_RECEIVED_DOCUMENTATION',
  NOT_REQUIRED_TO_ALLEVIATE = 'NOT_REQUIRED_TO_ALLEVIATE',
  WAS_NOT_PROVIDED_BY_ITSELF = 'WAS_NOT_PROVIDED_BY_ITSELF',
  NOT_REQUIRED_FOR_DISABILITY = 'NOT_REQUIRED_FOR_DISABILITY',
}
export enum EConversionReasonUI {
  SEVERAL_ATTEMPTS_TO_VERIFY = 'We have made several attempts to verify a disability-related need for an assistance animal with your healthcare provider, but regrettably, we have been unable to do so. Despite our requests for your assistance in facilitating communication with your provider, we have not received the necessary cooperation. Consequently, we are unable to proceed with the approval process at this time and your animal will have to be considered a pet. You may submit further documentation or have your provider contact us directly at any point during your lease, at which time we can resume the verification process.',
  NOT_RECEIVED_DOCUMENTATION = 'At this time, we have not received any documentation from a healthcare provider in support of your reasonable accommodation request. According to the HUD guidelines, a housing provider may request documentation from a licensed healthcare provider that verifies an individual’s non-observable disability and disability-related needs for an assistance animal as part of the process for approving a reasonable accommodation. Consequently, we are unable to proceed with the approval process at this time and your animal will have to be considered a pet. You may submit documentation at any point during your lease, at which time we can begin the verification process.',
  NOT_REQUIRED_TO_ALLEVIATE = 'It has been confirmed that this animal is not required to alleviate symptoms of a disability, therefore, the criteria for a reasonable accommodation has not been met. Consequently, this animal will have to be considered a pet. You may submit further documentation or clarification at any point during your lease, at which time we can resume the verification process.',
  WAS_NOT_PROVIDED_BY_ITSELF = 'In accordance with HUD guidelines, the documentation provided was not, by itself, sufficient to reliably establish that an individual has a non-observable disability and a disability-related need for an assistance animal. Consequently, we are unable to grant a reasonable accommodation at this time and your animal will have to be considered a pet. You may submit further documentation or clarification at any point during your lease, at which time we can resume the verification process.',
  NOT_REQUIRED_FOR_DISABILITY = 'The animal has been converted to a pet since you have indicated that the animal is not required for a disability. If you feel that this is an error, please contact us right away or submit a conversion request to begin the Assistance Animal verification process. You can reach us at ',
}

export const standartPlanPropertyManagements = ['Urban Land Interests'];

export const standartPlanProperties = [
  'The Townhomes at Woodmill Creek',
  'Urban Crest Lake Jackson',
  'SkyMor Cypress',
];

export const yesComminutyPropertyManagementId =
  '831019a9-49bc-436f-bcb8-f48caf22c9ca';
