export enum EOnboardingStepUI {
  RESIDENCY_INFORMATION = 'RESIDENCY_INFORMATION',
  ANIMAL_INFORMATION = 'ANIMAL_INFORMATION',
  PAYMENT = 'PAYMENT',
}

export enum EOnboardingSubStep {
  CHOOSE_APPLICATION_TYPE = 'CHOOSE_APPLICATION_TYPE',
  FILL_APPLICATION_INFO = 'FILL_APPLICATION_INFO',
  ANIMAL_COUNT = 'ANIMAL_COUNT',
  ANIMAL_CREATE = 'ANIMAL_CREATE',
  ANIMAL_COMPLETE = 'ANIMAL_COMPLETE',
  CREATE_NEW_ANIMAL = 'animal_',
  PAYMENT = 'PAYMENT',
  ANIMAL_SIGN_ADDENDUM = 'ANIMAL_SIGN_ADDENDUM',
}
