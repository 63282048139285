import { authSplitApi } from 'redux/helpers/slice.helpers';
import { EPolicyUrls } from 'data/urls/policy.url';
import { docHtmlConvertor } from 'data/convertors/policy.convertors';
import { TDocHtmlUI } from 'data/types/policy.types';
import { DocTypes } from 'data/types/application.types';

const convertDocTypeToUrl = (docType?: DocTypes | null) => {
  if (!docType) {
    return 'general';
  }
  if (docType === 'SERVICE_FORM') {
    return 'sa';
  }
  return docType.replace('_FORM', '').toLocaleLowerCase();
};

export const policyApi = authSplitApi('policy', ['esaDoc']).injectEndpoints({
  endpoints: build => ({
    getDocHtml: build.query<
      TDocHtmlUI,
      { leasePacketId?: string; docType: DocTypes; tenantName: string }
    >({
      query({ leasePacketId, docType, tenantName }) {
        const url = convertDocTypeToUrl(docType);
        return {
          url: `${EPolicyUrls.POLICY}/${EPolicyUrls.DOC}/${url}`,
          method: 'GET',
          responseHandler: 'text',
          params: { leasePacketId, tenantName },
        };
      },
      transformResponse: (data: string) => docHtmlConvertor.fromDb(data),
    }),
  }),
});

export const { useGetDocHtmlQuery } = policyApi;
